import {Component, EventEmitter, HostBinding, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {PrescriptionService} from '../../services/prescription.service';
import {assignIn, get, concat, assign, head, chain, noop} from 'lodash';
import {PRESCRIPTION_COLUMNS} from '../../utils/prescription-columns';
import {PhysicianService} from '../../../settings/services/physician.service';
import {CommentsDialogComponent} from '../../../shared/dialogs/comments-dialog/comments-dialog.component';
import {PRESCRIPTION_VIEW} from '../../utils/views-settings';
import {SettingsService} from '../../../settings/services/settings.service';
import {
    ActionItem, AppConfigsService,
    DefaultValuesService,
    FtWsService,
    MEDIUM_DIALOG,
    TableColumnDefinition,
} from '@ft/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {patients} from 'src/app/shared/samples/patients.sample';
import * as moment from 'moment';

@Component({
    selector: 'app-prescription-grid',
    templateUrl: './prescription-grid.component.html',
    styleUrls: ['./prescription-grid.component.scss'],
})
export class PrescriptionGridComponent implements OnInit {
    @HostBinding('class.host-class-mobile') public isMobile: boolean = false;
    public breakpoints = Breakpoints;
    public patientList = patients;
    subscription: Subscription;
    dataSource: Observable<any[]> = of([]);
    singleActions = {
        label: '',
        key: 'actions',
        type: 'actions',
        actions: [
            {
                class: 'mat-accent',
                icon: 'mdi-eye-outline',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.handlePrescription(item);
                },
                tooltip: this.translateService.instant('prescription.prescription_file'),
                acl: {resource: 'prescription', action: 'update', behavior: 'remove'},
            },
            {
                class: 'mat-accent',
                icon: 'mdi-progress-check',
                isMultipleSelection: false,
                method: (item, ev) => {
                    return this.closePrescription(item);
                },
                disabled: (row) => {
                    return get(row, 'status') === 'closed';
                },
                tooltip: this.translateService.instant('shared.closed'),
                acl: {resource: 'prescription', action: 'update', behavior: 'remove'},
            },
            // {
            //     class: 'mat-accent',
            //     icon: 'mdi-comment-text',
            //     isMultipleSelection: false,
            //     method: (item, ev) => {
            //         return this.addComment(item);
            //     },
            //     tooltip: this.translateService.instant('shared.comments'),
            //     acl: {resource: 'comments', action: 'update', behavior: 'remove'},
            // },
            // {
            //     class: 'mat-warn',
            //     icon: 'mdi-delete',
            //     isMultipleSelection: false,
            //     method: (item, ev) => {
            //         return this.removePrescription(get(item, 'id'));
            //     },
            //     tooltip: this.translateService.instant('shared.delete'),
            //     acl: {resource: 'prescription', action: 'delete', behavior: 'remove'},
            // },
        ],
    };
    multiActions: ActionItem[] = [
        {
            class: 'mat-accent',
            icon: 'mdi-plus',
            method: (item, ev) => {
                return this.handleNewPrescription();
            },
            tooltip: this.translateService.instant('prescription.new'),
            acl: {resource: 'prescription', action: 'create', behavior: 'remove'},
        },
    ];
    stateColors = [];
    physicianColumn = [
        {
            label: 'settings.physician',
            key: 'physician.last_name',
            sortBy: 'last_name',
            type: 'text',
            is_global_searchable: true,
            callBack: (item) => {
                return `${get(item, 'physician.last_name', '')} ${get(item, 'physician.first_name', '')}`;
            },
            is_ref: true,
            model: 'staff.Physician'
        },
        // {
        //     label: 'settings.physician_first_name',
        //     key: 'physician.first_name',
        //     type: 'text',
        //     is_global_searchable: true,
        // },
    ];
    columns = concat(PRESCRIPTION_COLUMNS, [
        {
            label: 'prescription.status',
            key: 'prescription_status',
            type: 'icon',
            callBack: (row) => {
                return 'mdi-check-circle';
            },
            style: (item) => this.handleStatusColor(item),
            tooltip: (item) => get(item, 'status') ? this.translateService.instant('shared.' +
                get(item, 'status')) : ''
        },
        {
            label: 'shared.type',
            key: 'template_name',
            type: 'badge',
            is_global_searchable: false,
            style: (item) => this.TemplatesColors(item)
        }

    ]);
    reloadEvent = new EventEmitter();
    defaultQuery = '';
    defaultPhysician = null;
    viewSettings = PRESCRIPTION_VIEW;
    customFilters = [
        {
            label: 'prescription.in_progress', query: [
                {
                    value: 'entered',
                    operator: '$eq',
                    logical_operator: 'and',
                    key: 'status',
                    type: 'text'
                }
            ]
        },
        {
            label: 'prescription.expired', query: [
                {
                    value: 'closed',
                    operator: '$ne',
                    logical_operator: 'and',
                    key: 'status',
                    type: 'text'
                },
                {
                    value: moment().format('YYYY-MM-DD'),
                    operator: '$lt',
                    logical_operator: 'and',
                    key: 'end_date',
                    type: 'date'
                }
            ]
        },
        // {
        //     label: 'prescription.non_conforme', query: [
        //         {
        //             value: 'non_conforme',
        //             operator: '$eq',
        //             logical_operator: 'and',
        //             key: 'status',
        //             type: 'text'
        //         }
        //     ]
        // },
        {
            label: 'prescription.suspended', query: [
                {
                    value: true,
                    operator: '$exists',
                    logical_operator: 'and',
                    key: 'suspend_details',
                    type: 'text'
                }
            ]
        }
    ];
    prescriptionTemplatesColors = this.defaultValuesService.getValue(
        'prescriptionTemplatesColors'
    );

    constructor(
        public breakpointObserver: BreakpointObserver,
        private prescriptionService: PrescriptionService,
        public translateService: TranslateService,
        private dialog: MatDialog,
        private settingService: SettingsService,
        private physicianService: PhysicianService,
        private defaultValuesService: DefaultValuesService,
        private settingsService: SettingsService,
        private router: Router,
        private ws: FtWsService,
        private app_configService: AppConfigsService
    ) {
        this.stateColors = this.defaultValuesService.getValue(
            'prescriptionStateColors'
        );

        this.viewSettings.viewSettings.viewColumns = concat(
            (!this.settingsService.isDoctor() ? this.physicianColumn : [], this.columns as any),
            this.singleActions
        );
        this.subscription = this.ws
            .observe('prescription.handle_refresh')
            .subscribe((data) => {
                if (data) {
                    this.reloadEvent.emit(true);
                }
            });
    }

    ngOnInit(): void {
        this.breakpointObserver
            .observe(['(max-width: 640px)'])
            .subscribe((result) => {
                if (result.breakpoints['(max-width: 640px)']) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            });
    }

    removePrescription(pk) {
        this.subscription = this.prescriptionService
            .deletePrescription(pk)
            .subscribe((data) => {
                this.reloadEvent.emit(true);
            });
    }

    handlePrescription(obj) {
        //todo Check
        this.router.navigate([`prescription/content`, obj.id]);
    }

    closePrescription(obj) {
        this.subscription = this.prescriptionService
            .savePrescription(assign(obj, {status: 'closed'}))
            .subscribe((data) => {
                obj = data;
            });
    }

    handleStateColor(row) {
        return {
            color: (chain(this.stateColors)
                .find({name: get(row, 'status')}) as any)
                .get('color')
                .value(),
        };
    }

    addComment(item) {
        this.dialog
            .open(
                CommentsDialogComponent,
                assignIn(MEDIUM_DIALOG, {
                    data: {pk: get(item, 'id')},
                    disableClose: true,
                    autoFocus: false,
                })
            )
            .afterClosed()
            .subscribe((data) => {
            });
    }

    handleAdd() {
        this.router
            .navigate(['prescription', 45], {
                queryParams: {name: get(head(this.settingsService.prescriptionContentTemplates), 'name')},
            })
            .then(noop);
    }

    handleNewPrescription() {
        this.router.navigate(['prescription/content/']);
    }

    handlePrescriptionEdit(pk) {
        this.router.navigate([`prescription/content/${pk}`]);
    }

    TemplatesColors(item) {
        return {
            'background-color': (chain(this.prescriptionTemplatesColors).find({
                name: get(item, 'template_name')
            }) as any).get('color', 'rgb(255, 204, 153)').value()
        };
    }

    handleStatusColor(item) {
        return {
            'color': (chain(this.defaultValuesService.getValue(
                'prescriptionStateColors'
            )).find({
                name: get(item, 'status')
            }) as any).get('color', 'rgb(255, 204, 153)').value()
        };
    }
     handleStatusToolTip(item) {
        return get(item, 'status') ? this.translateService.instant('shared.' +
                get(item, 'status')) : '';
    }
}
