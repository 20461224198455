export let lang = {
  generals: 'Paramètres généraux',
  app_config: "Configuration de l'application",
  base_settings: 'Paramètres de base',
  settings: 'Paramètres',
  printing_setup: "Templates d'impression",

  printing: {
    setup_title: "Gestion d'impression",
    new_template: 'Nouvelle template',
    global_params: 'Paramètres généraux',
    images_list: 'Images',
    template_name: 'Nom Unique',
    template_label: 'Titre à afficher',
    template_is_hidden: 'Cachée',
    template_is_default: 'Par défaut',
    template_is_disabled: 'Désactivée',
    groups: 'Groups',
  },
  prescription: {
    setup_title: 'Gestion des prescriptions',
    new_template: 'Nouvelle template',
    templates: 'Templates',
  },
  physician_list: 'Liste des médecins',
  physician: 'Médecin traitant',
  prescription_setup: 'Templates de prescription',
  general: 'Système',
  state: 'État',
  prescription_states: 'Status de prescription',
  physician_last_name: 'Médecin.Nom',
  physician_first_name: 'Médecin.Prénom',
  createAccount: 'Créer un Compte',
  profiles: 'Gestion des profiles',
  profile_name: 'Nom du profile',
  hasAccount: "Un compte d'utilisateur existe!",
  users: 'Gestion des utilisateurs',
  user_account: 'Compte Utilisateur',
  sms_setup: 'Gestion des SMS',
  application_config: "Configuration de l'application",
  access_control: 'Gestion des accès',
  sms_server: 'Serveur SMS',
  sms_history: 'Historique SMS',
  phone_number: 'Téléphone',
  content: 'Contenu',
  status: 'Status',
  sms_identifier: 'ID',
  speciality: 'Spécialité',
  inpe: 'INPE',
  ice: 'ICE',
  speciality_list: 'Liste des spécialités',
  sms_templates: 'Templates SMS',
  sms_template_list: 'Liste des Templates',
  acl: {
    comment: 'Commentaire',
    comment_hint: 'Commentaire',
    measurement: 'Mesures',
    measurement_hint: 'Mesures',
    sms_message: 'SMS Message',
    sms_message_hint: 'SMS Message',
    sms_template: 'Template SMS',
    sms_template_hint: 'Template SMS',
    sms_history: 'SMS Historique',
    sms_history_hint: 'SMS Historique',
    patient: 'Patient',
    patient_hint: 'Patient',
    prescription: 'Prescription',
    prescription_hint: 'Prescription',
    prescription_template: 'Prescription template',
    prescription_template_hint: 'Prescription template',
    speciality: 'Speciality',
    speciality_hint: 'Indication de specialité',
    physician: 'Médecin',
    technician: 'Technicien',
    physician_hint: 'Médecin',
    get_physician_by_user_id: 'Chercher par utilisateur',
    load_staff_detail: 'Afficher Staff',
    form_template_category: 'Catégorie de template de formulaire',
    form_template_category_hint:
      'Indication de catégorie de modèle de formulaire',
    form_template: 'Template de formulaire',
    form_template_hint: 'Form_template',
    prescription_exam_form: "Formulaire d'examen de prescription",
    prescription_exam_form_hint: "Indication pour le formulaire d'examen de prescription",
    prescription_satisfaction_form:
      'Formulaire de satisfaction de prescription',
    prescription_satisfaction_form_hint: 'Indication pour le formulaire de satisfaction de prescription',
    notification: 'Notification',
    add_notification_config: 'Ajouter une configuration de notification',
    "action-notifier": "Notifiant d'action",
    reminder: 'Rappel',
    appointment: 'Rendez-vous',
    ticket: 'Ticket',
    'telephone-follow-up-plan': 'Plan de suivi',
    'tfu-model': 'Modele de suivi téléphonique',
    'patient-request': 'Demande de patient',
    notification_hint: 'Notification',
    receipt_bulk: 'Reçevoir en masse',
    receive_bulk: 'Recevoir en masse',
    read: 'Lire',
    receive: 'Recevoir',
    cancel: 'Annuler',
    complete: 'Terminer',
    undo: 'Re-démarrer',
    pause: 'Pause',
    resume: 'Reprendre',
    start: 'Commencer',
    reject: 'Rejeter',
    accept: 'Accepter',
    'patient-prescription': 'Prescription du patient',
    undo_completion: 'Annuler la terminaison',
    reschedule: 'Replanifier',
    uncancel: "Annuler l'annulation",
    confirm:"Confirmer"
  },
  user: 'Utilisateur',
  is_doctor: 'Médecin',
  prescription_forms_setup: "Formulaires d'intervention",
  PRESCRIPTION_TEMPLATE: 'Template de Prescription',
  PRESCRIPTION_EXAM_FORM: 'Formulaire médicaux',
  PRESCRIPTION_SATISFACTION_FORM: 'Formulaire de satisfaction',
  is_default: 'Par défaut',
  prescription_templates_colors: 'Templates des prescriptions',
  search: 'Rechercher',
  ticket_status_colors: 'Status des ticket',
  appointment_status_colors: 'Status des rendez-vous',
  MISSED: 'RATÉ',
  REJECTED: 'REJETÉ',
  ACCEPTED: 'ACCEPTÉ',
  COMPLETED: 'TERMINÉ',
  IN_PROGRESS: 'EN COURS',
  ON_HOLD: 'EN ATTENTE',
  CANCELLED: 'ANNULÉ',
  SCHEDULED: 'PLANIFIER',
  RESCHEDULED: 'REPORTÉ',
  CONFIRMED: 'CONFIRMÉ',
  DONE: 'TERMINÉ',
  done: 'Fait',
  in_progress: 'En cours',
  RESCHEDULE_REQUESTED: 'Replanification demandée',
  CANCEL_REQUESTED: 'Annulation demandée',
  PATIENT_EDIT_INFO: 'Modification du patient',
  notifications: 'Notifications',
  notification: 'Notification',
  add_notification_config: 'Ajouter une configuration de notification',
  reminders: 'Rappels',
  reminder: 'Rappel',
  action_notifiers: "Notifiants d'actions",
  the_actions_notifiers: "Les notifiants d'actions",
  action_notifier: "Notifiant d'action",
  add_reminder: 'Ajouter un rappel',
  add_reminders: 'Ajouter des rappels',
  edit_notification: 'Éditer la configuration de notification',
  select_notification_recipients:
    'Sélectionner les destinataires de la notification',
  select_action: 'Sélectionner une action',
  recipients: 'Destinataires',
  actions: 'Actions',
  enter_title: 'Saisir un titre',
  steps_follow_up: 'Les étape de suivi du patient',
  step_follow_up: 'Étape de suivi du patient',
  a_step_follow_up: 'Une étape de suivi du patient',
  edit: 'Éditer',
  delete: 'Supprimer',
  add_step_follow_up: 'Ajouter une étape de suivi du patient',
  'telephone-follow-up-plan-model': 'Modèle de plan de suivi',
  'tfu-models': 'Les modèles des plans de suivis téléphoniques',
  title: 'Titre',
  enter_comment: 'Saisir un commentaire',
  complete_follow_up_step_question: "Voulez-vous terminer l'étape de suivi du patient?",
  delete_follow_up_step_question: "Voulez-vous supprimer cette étape de suivi du patient?",
  delete_follow_up_model_question: "Voulez-vous supprimer ce modèle de plan de suivi du patient?",
  delete_action_notifier_question: "Voulez-vous supprimer ce notifiant d'action?",
  delete_reminder_question: "Voulez-vous supprimer ce rappel?",
  steps_number: "Nbre d\'etapes",
  please_select_an_action: 'Veuillez sélectionner une action.',
  please_select_recipients: 'Veuillez sélectionner des destinataires',
  please_select_profiles: 'Veuillez sélectionner des profils',
  please_select_users: 'Veuillez sélectionner des utilisateurs',
  please_enter_title: 'Veuillez saisir un titre',
  please_add_reminder: 'Veuillez ajouter un rappel',
  please_select_an_sms_template: 'Veuillez sélectionner une template d\'SMS',
  others: 'Autres',
  other: 'Autre',
  no_translation_for: "Aucune traduction pour",
  at_least_one_reminder_is_required: "Au moins un rappel est requis",
};
