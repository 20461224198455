import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { lang } from './i18n';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { NgBusyModule } from 'ng-busy';
import { PatientModule } from './patient/patient.module';
import { CustomBusyComponent } from './custom-busy/custom-busy.component';
import { TicketModule } from './ticket/ticket.module';
import { PatientAppointmentModule } from './patient-appointment/patient-appointment.module';
import { PatientRequestModule } from './patient-request/patient-request.module';
import { PatientPrescriptionModule } from './patient-prescription/patient-prescription.module';
import { MainComponent } from './main.component';
import { PrescriptionModule } from './prescription/prescription.module';
import { SettingsModule } from './settings/settings.module';
import { SettingsService } from './settings/services/settings.service';
import { JwtModule } from '@auth0/angular-jwt';
import {
  AppConfigsService,
  DefaultValuesService,
  FtAuthModule,
  FtCoreModule,
  ftTranslateFactory,
  FtWsConnectionService,
  FtWsModule,
  JwtConfig,
  MaterialModule,
  POST_AUTH_CONFIG,
  PrintingService,
  WS_CONFIG,
} from '@ft/core';
import { FtTableModule } from '@ft/table';
import {
  FILE_MANAGER_ROOT_CONFIG,
  FileManagerModule,
  FtFileManagerSharedModule,
  FtVisualizerModule,
  MediaModule,
} from '@ft/file-manager';
import { FormConfigService, FormSetupService } from '@ft/form';
import { LayoutModule } from '@angular/cdk/layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');
export function ticketTokenGetter() {
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InJvb3QiLCJpYXQiOjE2OTI4ODY4NDksImV4cCI6MTY5MzQ5MTY0OSwianRpIjoiYWNhZTE1OTgtYjFiMC00YWM0LTlmNzYtZWEwZjNmZjg5OGZjIiwidXNlcl9pZCI6MSwidXNlcl9wcm9maWxlX2lkIjpbMV0sIm9yaWdfaWF0IjoxNjkyODg2ODQ5fQ.NL0Brjua-zz1V5j1q4z42mplK3l1_xSLok490lBtWFw';
}
// let ftTokenGetter = JwtConfig.config.tokenGetter
// JwtConfig.config.tokenGetter = function(request: HttpRequest<any>) {
//           // Check the request URL and decide which token to return
//           if (request.url.startsWith('/api/pl/ticket')) {
//             // Return the access token for example.com API
//             return ticketTokenGetter();
//           } else {
//             // Return null or empty string to avoid sending Authorization header
//             return ftTokenGetter();
//           }
// }

export const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: ftTranslateFactory(lang),
  },
};

export function postAuthFactory(
  defaultValuesService: DefaultValuesService,
  printingService: PrintingService,
  settingsService: SettingsService,
  formService: FormConfigService,
  formSetupService: FormSetupService
) {
  return [
    printingService.load(),
    defaultValuesService.load(),
    settingsService.loadStaff(),
    formService.load(),
    formSetupService.loadFormsTemplates(),
  ];
}

const rootConfig = {
  provide: FILE_MANAGER_ROOT_CONFIG,
  useValue: {
    prescription: 'prescription.models.Prescription',
    patient: 'patient.models.Patient',
  },
};

/**
  A utility module adding I18N support for Storybook stories
  @see https://dsebastien.medium.com/using-ngx-translate-in-storybook-stories-3f4228f80e02
 **/
@NgModule({
  imports: [TranslateModule.forRoot(translateConfig)],
})
export class StorybookTranslateModule {
  constructor(translateService: TranslateService) {
    translateService.setDefaultLang('fr');
    translateService.use('fr');
  }
}

@NgModule({
  declarations: [AppComponent, MainComponent, CustomBusyComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    TranslateModule.forRoot(translateConfig),
    NgBusyModule,
    FtCoreModule,
    FtWsModule,
    JwtModule.forRoot(JwtConfig),
    FtAuthModule.forRoot({
      provide: POST_AUTH_CONFIG,
      deps: [
        DefaultValuesService,
        PrintingService,
        SettingsService,
        FormConfigService,
        FormSetupService,
      ],
      useFactory: postAuthFactory,
    }),
    FtWsModule.forRoot({
      provide: WS_CONFIG,
      useFactory: () => new Object({ path: 'ws' }),
    }),
    FtTableModule,

    AppRoutingModule,
    SharedModule,
    PatientModule,
    PrescriptionModule,
    TicketModule,
    PatientAppointmentModule,
    PatientRequestModule,
    PatientPrescriptionModule,
    SettingsModule,
    LayoutModule,

    FtFileManagerSharedModule,
    FileManagerModule.forRoot(rootConfig),
    FtVisualizerModule,
    MediaModule,
    InfiniteScrollModule,
  ],
  providers: [
    {
      multi: true,
      provide: APP_INITIALIZER,
      deps: [AppConfigsService],
      useFactory: (config: AppConfigsService) => () =>
        config.load('/api/system/'),
    },
    {
      multi: true,
      provide: APP_INITIALIZER,
      deps: [FtWsConnectionService],
      useFactory: (config: FtWsConnectionService) => () =>
        config.connect().toPromise(),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {provide: LOCALE_ID, useValue: 'fr' },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
