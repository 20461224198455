export let appointmentFr: Record<string, any> = {
    tickets_list: 'Liste des tickets',
    calendar: {
        today: "Aujourd'hui",
        day: "Jour",
        week: "Semaine",
        month: "Mois",
        four_days: "4 Jours",
        list: "Liste",
        months_of_year: {
            January: "Janvier",
            February: "Février",
            March: "Mars",
            April: "Avril",
            May: "Mai",
            June: "Juin",
            July: "Juillet",
            August: "Août",
            September: "Septembre",
            October: "Octobre",
            November: "Novembre",
            December: "Décembre",
          },
    },
    map: "Carte",
    following_interventions: "Interventions suivantes",
    no_appointments_to_display: "Malheureusement, il n'y a aucun rendez-vous disponible à afficher.",
    no_map_locations_available: "Malheureusement, il n'y a pas de coordonnées de carte disponibles pour l'affichage.",
    details: "Détails",
    complete: "Terminer",
    cancel_the_intervention: "Annuler l'intervention",
    satisfaction_form: "Formulaire de satisfaction",
    action: {
        cancel: "Annuler",
        complete: "Terminer",
        undo: "Re-démarrer",
        pause: "Pause",
        resume: "Reprendre",
        start: "Commencer",
        reject: "Rejeter",
        accept: "Accepter",
        display_additional_actions: "Afficher les actions additionnelles",
    },
    passage_proof: {
        delete: "Supprimer",
        preview: "Voir",
        proof_of_passage: "Preuves de passage",
        upload_images: "Ajouter des photos",
        comment: "Commentaire",
        no_images_to_display: "Aucune image à afficher",
    },
  exist_satisfaction_form_warning:'Le formulaire de satisfaction a déjà été rempli',
  save_satisfaction_form_success:'Votre avis a été enregistré avec succès',
  form_missed_ticket_warning:'Aucun formulaire de satisfaction de service n\'est disponible, veuillez contacter le support',
  no_satisfaction_form_to_display:'Aucun avis de client pour le moment!',
  satisfaction_form_rating:"Noter le service fourni"
};
