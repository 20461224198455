import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './components/main/main.component';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgBusyModule} from 'ng-busy';
import {SharedModule} from '../shared/shared.module';
import {SettingsRoutingModule} from './settings-routing.module';
import {PhysicianGridComponent} from './components/physician-grid/physician-grid.component';
import {PhysicianService} from './services/physician.service';
import {PhysicianSetupComponent} from './dialogs/physician-setup/physician-setup.component';
import {SettingsService} from './services/settings.service';
import {BaseSettingsComponent} from './components/base-settings/base-settings.component';
import {SmsSetupComponent} from './components/sms-setup/sms-setup.component';
import {SpecialityGridComponent} from './components/speciality-grid/speciality-grid.component';
import {SpecialitySetupComponent} from './dialogs/speciality-setup/speciality-setup.component';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {CommonStaffDialogComponent} from './dialogs/common-staff-dialog/common-staff-dialog.component';
import {PrescriptionFormsComponent} from './components/prescription-forms/prescription-forms.component';
import {FtFormModule} from '@ft/form';
import {FtTableModule, FtViewService} from '@ft/table';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ngfModule} from "angular-file";
import { ActionNotifierItemComponent } from './components/action-notifier/action-notifier-item/action-notifier-item.component';
import { TfuModelComponent } from './components/tfu/tfu-model/tfu-model.component';
import { ActionNotifierListComponent } from './components/action-notifier/action-notifier-list/action-notifier-list.component';
import { TfuBaseComponent } from './components/tfu/tfu-base/tfu-base.component';
import { TfuModelListComponent } from './components/tfu/tfu-model-list/tfu-model-list.component';
import { TfuModelListBaseComponent } from './components/tfu/tfu-model-list-base/tfu-model-list-base.component';
import { TfuModelListForPrescriptionComponent } from './components/tfu/tfu-model-list-for-prescription/tfu-model-list-for-prescription.component';


@NgModule({
    declarations: [
        MainComponent,
        PhysicianGridComponent,
        PhysicianSetupComponent,
        BaseSettingsComponent,
        SmsSetupComponent,
        SpecialityGridComponent,
        SpecialitySetupComponent,
        CommonStaffDialogComponent,
        PrescriptionFormsComponent,
        ActionNotifierItemComponent,
        TfuModelComponent,
        ActionNotifierListComponent,
        TfuBaseComponent,
        TfuModelListComponent,
        TfuModelListBaseComponent,
        TfuModelListForPrescriptionComponent

    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        NgBusyModule,
        FtTableModule,
        SharedModule,
        SettingsRoutingModule,
        FtCoreModule,
          DragDropModule,
    ngfModule,
        FtFormModule,

    ],
    providers: [
        PhysicianService,
        SettingsService,
        FtViewService
    ]
})
export class SettingsModule {
}
