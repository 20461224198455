<mat-toolbar *ngIf="!isMobile">
  <ol class="pr-breadcrumb">
    <li routerLink="/" class="hidden md:flex">
      <button type="button" mat-icon-button tabindex="-2">
        <mat-icon fontSet="mdi" fontIcon="mdi-home"></mat-icon>
      </button>
    </li>
    <li routerLink="/patient/list" class="hidden md:flex">
      {{ "patient.patient_list" | translate }}
    </li>
    <li *ngIf="!patient?.id">{{ "patient.new_patient" | translate }}</li>
    <li *ngIf="patient?.id">{{'patient.patient_file'|translate}}</li>

  </ol>

  <div class="ft-actions">
    <button
      *ngIf="breakpointObserver.isMatched('(min-width: 960px)')"
      type="button"
      mat-flat-button
      class="cancel-btn"
      (click)="handleCancel()"
      cdkFocusInitial
    >
      <span>{{ "shared.reset" | translate }}</span>
    </button>
    <button
      type="button"
      mat-flat-button
      color="primary"
      (click)="handleSave()"
      [disabled]="form.invalid"
      (ftAsyncSubmit)="asyncClick($event)"
    >
      <span>{{ "shared.save" | translate }}</span>
    </button>
  </div>
</mat-toolbar>
<div class="ft-content padding form-container">
  <form #form="ngForm">
    <div [ngBusy]="subscription"></div>
    <div class="form-content flex flex-col lg:flex-row">
      <div class="mr-3 profile-image lg:flex-none lg:w-40 flex justify-center">
        <div
          class="p-4 w-fit h-fit lg:w-36 lg:flex lg:justify-center lg:items-center lg:border lg:border-dashed lg:border-2 lg:rounded-lg"
        >
          <div class="profile-image-container">
            <div class="profile-image-inner-container">
              <img
                class="rounded-full"
                [src]="
                  patient?.picture
                    ? patient?.picture
                    : '/assets/icons/default-avatar.svg'
                "

                alt="avatar"
              />

              <button
                mat-mini-fab
                color="primary"
                ngfSelect
                accept="image/*"
                [(file)]="uploadedAvatar"
                (filesChange)="uploadAvatar($event)"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-upload"></mat-icon>
              </button>
            </div>
            <div class="mt-8 flex justify-center">
              <mat-slide-toggle [(ngModel)]="patient.insured" name="insured">
                <span>{{ "patient.insured" | translate }}</span>
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-fields lg:flex-1">
        <h1 class="text-center md:text-left">
          {{ "shared.general_infos" | translate }}
        </h1>
        <div class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">
          <mat-form-field
            [appearance]="!isMobile ? 'outline' : 'standard'"
            class="lg:col-span-2 md:col-span-3 col-span-12"
          >
            <mat-label>{{ "shared.title" | translate }}</mat-label>
            <mat-select
              [(ngModel)]="patient.title"
              name="title"
              [compareWith]="compareCondition"
              (ngModelChange)="handleTitleChange($event)"
            >
              <mat-option *ngFor="let t of titles" [value]="t">
                {{ t.value }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="patient.title"
              matSuffix
              mat-icon-button
              type="button"
              color="warn"
              [matTooltip]="'shared.clean' | translate"
              (click)="$event.stopImmediatePropagation(); patient.title = null"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field
            [appearance]="!isMobile ? 'outline' : 'standard'"
            class="lg:col-span-5 md:md:col-span-9 col-span-12"
          >
            <mat-label>{{ "patient.last_name" | translate }}</mat-label>
            <input
              matInput
              type="text"
              required
              name="last_name"
              [ftAutoFocus]="true"
              [(ngModel)]="patient.last_name"
              (ngModelChange)="patient.last_name = $event.toUpperCase()"
            />
          </mat-form-field>

          <mat-form-field
            [appearance]="!isMobile ? 'outline' : 'standard'"
            class="lg:col-span-5 md:col-span-9 col-span-12"
          >
            <mat-label>{{ "patient.first_name" | translate }}</mat-label>
            <input
              matInput
              type="text"
              required
              name="first_name"
              [(ngModel)]="patient.first_name"
              (ngModelChange)="patient.first_name = $event.toUpperCase()"
            />
          </mat-form-field>

          <mat-form-field [appearance]="'standard'"
                          floatLabel="always"
                          class="ft-radio-field xl:col-span-2 lg:col-span-3 md:col-span-3 col-span-12"
          >
            <mat-label>{{ "patient.gender" | translate }}</mat-label>
            <input matInput style="display: none"/>
            <mat-radio-group [(ngModel)]="patient.gender" name="b_type">
              <mat-radio-button
                *ngFor="let b_type of ['MALE', 'FEMALE']"
                [value]="b_type"
              >
                {{ "patient." + b_type | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </mat-form-field>
          <div class="lg:col-span-5 md:col-span-6 col-span-12 grow-0">
            <mat-form-field
              [appearance]="!isMobile ? 'outline' : 'standard'"
              class="grow-0"
            >
              <mat-label>{{ "patient.birth_date" | translate }}</mat-label>
              <input
                matInput
                ftDate
                [matDatepicker]="b_date"
                required
                #dateModel="ngModel"
                [(ngModel)]="patient.birth_date"
                name="b_date"
              />
              <mat-datepicker-toggle matSuffix [for]="b_date">
                <mat-icon
                  matDatepickerToggleIcon
                  fontSet="mdi"
                  fontIcon="mdi-calendar-today"
                ></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #b_date></mat-datepicker>
            </mat-form-field>
          </div>
          <mat-form-field
            [appearance]="!isMobile ? 'outline' : 'standard'"
            class="xl:col-span-5 lg:col-span-4 md:col-span-3 col-span-12"
          >

            <mat-label #ageLabel>{{ "shared.age" | translate }}</mat-label>
            <input
              matInput
              placeholder="{{'shared.age' | translate}}"
              [(ngModel)]="patient.age"
              name="age"
              ftNumber="float"
              [ftAgHandler]="dateModel"
              [ftAgLabel]="ageLabel"
              [fix]="0"
            />
          </mat-form-field>

          <mat-form-field
            [appearance]="!isMobile ? 'outline' : 'standard'"
            class="with-error-label lg:col-span-4 md:col-span-3 col-span-12"
          >
            <mat-label>{{ "patient.national_id" | translate }}</mat-label>
            <input
              matInput
              type="text"
              name="national_id"
              [(ngModel)]="patient.national_id"
            />
          </mat-form-field>
          <mat-form-field
            [appearance]="!isMobile ? 'outline' : 'standard'"
            class="lg:col-span-4 md:col-span-4 col-span-12"
          >
            <mat-label>{{ "patient.maritalStatus" | translate }}</mat-label>
            <mat-select
              [(ngModel)]="patient.marital_status"
              name="title"
              [compareWith]="compareCondition"
            >
              <mat-option *ngFor="let t of maritalStatus" [value]="t">
                {{ t.value }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="patient.marital_status"
              matSuffix
              mat-icon-button
              type="button"
              color="warn"
              [matTooltip]="'shared.clean' | translate"
              (click)="$event.stopImmediatePropagation(); patient.marital_status = null"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-4 col-span-12"
          >
            <mat-label>{{'patient.profession'|translate}}</mat-label>
            <mat-select
              [(ngModel)]="patient.profession"
              name="title"
              [compareWith]="compareCondition"
            >
              <mat-option *ngFor="let t of professions" [value]="t">
                {{ t.value }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="patient.profession"
              matSuffix
              mat-icon-button
              type="button"
              color="warn"
              [matTooltip]="'shared.clean' | translate"
              (click)="$event.stopImmediatePropagation(); patient.profession = null"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            *ngIf="!isDoctor"
            class="lg:col-span-4 md:col-span-6 col-span-12"
          >
            <mat-label>{{'settings.physician' | translate}}</mat-label>
            <pr-select-search [config]="physicianSelectConfig"
                              [(ngModel)]="patient.physician" name="physician"
                              #physicianModel="ngModel" [dialogOpener]="opener"
            >
            </pr-select-search>
            <button matSuffix mat-icon-button color="primary" [ftDialogOpener]="physicianDialogClass"
                    [dialogConfig]="{data: {listDefinition: {url: 'api/pl/physician/'}}}"
                    #opener="ftDialogOpener">
              <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            </button>
            <button matSuffix mat-icon-button color="warn" *ngIf="patient?.physician"
                    [ftValueUnset]="physicianModel">
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-6 col-span-12"
          >
            <mat-label>{{'patient.insurance' | translate}}</mat-label>
            <pr-select-search [config]="organizationSelectConfig" [disabled]="!patient?.insured"
                              [(ngModel)]="patient.insurance" name="insurance"
                              #insuranceModel="ngModel"
            >
            </pr-select-search>
            <button matSuffix mat-icon-button color="warn" *ngIf="patient?.insurance"
                    [ftValueUnset]="insuranceModel">
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>

          <ng-template [ftAcl]="{resource: 'user', action: 'create'}">
            <mat-form-field
              [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
              class="lg:col-span-4 md:col-span-6 col-span-12"
            >
              <mat-label>{{'settings.user'| translate}}</mat-label>
              <pr-select-search [config]="staffSelectConfig"
                                [(ngModel)]="patient.default_staff" name="default_staff"
                                #defaultStaffModel="ngModel"
              >
              </pr-select-search>
              <button matSuffix mat-icon-button color="warn" *ngIf="patient?.default_staff"
                      [ftValueUnset]="defaultStaffModel">
                <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
              </button>
            </mat-form-field>
          </ng-template>

        </div>
        <h1 class="text-center md:text-left">{{ 'patient.contact_infos' | translate }}</h1>
        <div class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-6 col-span-12"
          >
            <mat-label>{{ "patient.phone_number" | translate }}
              {{phoneNumber.invalid ? ("patient.phone_invalid"|translate) : ''}}
            </mat-label>
            <input
              matInput
              type="tel"
              name="phone_numbers"
              #phoneNumber="ngModel"
              [(ngModel)]="patient.phone_number"
              pattern="^\+?[0-9]{5,12}$"
            />
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-12 col-span-12"
          >
            <mat-label>{{
              "patient.emergency_contact_name" | translate
              }}</mat-label>
            <input
              matInput
              name="emergency_contact_name"
              [(ngModel)]="patient.emergency_contact_name"
            />
          </mat-form-field>

          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-12 col-span-12"
          >
            <mat-label>{{
              "patient.emergency_phone_number" | translate
              }}</mat-label>
            <input
              matInput
              type="tel"
              name="emergency_phone_numbers"
              [(ngModel)]="patient.emergency_phone_number"
              pattern="^\+?[0-9]{5,12}$"
            />
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-6 col-span-12"
          >
            <mat-label>{{ "patient.email" | translate }}</mat-label>
            <input
              matInput
              type="email"
              name="email"
              [(ngModel)]="patient.email"
              pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"
            />
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-4 col-span-12"
          >
            <mat-label>{{ "shared.country" | translate }}</mat-label>
            <mat-select
              [(ngModel)]="patient.country"
              name="country"
              [compareWith]="compareCondition"
              ftDefaultValues="defaultParams.country"
            >
              <mat-option *ngFor="let t of countries" [value]="t">
                {{ t.full_name }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="patient.country"
              matSuffix
              mat-icon-button
              type="button"
              color="warn"
              [matTooltip]="'shared.clean' | translate"
              (click)="
                $event.stopImmediatePropagation(); patient.country = null
              "
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-4 col-span-12"
          >
            <mat-label>{{ "shared.city" | translate }}</mat-label>
            <mat-select
              [(ngModel)]="patient.city"
              name="city"
              [compareWith]="compareCondition"
              ftDefaultValues="defaultParams.city"
            >
              <mat-option *ngFor="let t of cities" [value]="t">
                {{ t.full_name }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="patient.city"
              matSuffix
              mat-icon-button
              type="button"
              color="warn"
              [matTooltip]="'shared.clean' | translate"
              (click)="$event.stopImmediatePropagation(); patient.city = null"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-4 md:col-span-4 col-span-12"
          >
            <mat-label>{{'patient.province'|translate}}</mat-label>
            <mat-select
              [compareWith]="compareCondition"
            >
              <mat-option *ngFor="let t of provinces" [value]="t">
                {{ t.full_name }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="false"
              matSuffix
              mat-icon-button
              type="button"
              color="warn"
            >
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">

          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-6 md:col-span-6 col-span-12"
          >
            <mat-label>{{ "shared.address" | translate }}</mat-label>
            <textarea
              matInput
              [(ngModel)]="patient.address"
              name="address"
            ></textarea>
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-6 md:col-span-6 col-span-12"
          >
            <mat-label>{{
              "patient.intervention_address" | translate
              }}</mat-label>
            <textarea
              matInput
              [(ngModel)]="patient.intervention_address"
              name="intervention_address"
            ></textarea>
          </mat-form-field>
          <mat-form-field
            [appearance]="
              !isMobile
                ? 'outline'
                : 'standard'
            "
            class="lg:col-span-12 col-span-12"
          >
            <mat-label>{{ "shared.comment" | translate }}</mat-label>
            <textarea
              matInput
              [(ngModel)]="patient.comment"
              name="comment"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<button
  mat-mini-fab
  class="action-btn"
  color="primary"
  (click)="handleSave()"
  [disabled]="form.invalid"
  *ngIf="isMobile"
  (ftAsyncSubmit)="asyncClick($event)"

>
  <mat-icon fontSet="mdi" fontIcon="mdi-content-save"></mat-icon>
</button>
