import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {get} from 'lodash';
import {TITLES} from '../../../shared/utils/consts';
import {Subscription} from 'rxjs';
import {PhysicianService} from '../../services/physician.service';
import {SettingsService} from '../../services/settings.service';

@Component({
    selector: 'app-physician-setup',
    templateUrl: './physician-setup.component.html',
    styleUrls: ['./physician-setup.component.scss']
})
export class PhysicianSetupComponent implements OnInit {
    physician: any = {gender: 'MALE'};
    titles = TITLES;
    subscription: Subscription;
    role = null;
    specialities = [];
    staffs: any = [];

    constructor(public dialogRef: MatDialogRef<PhysicianSetupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public physicianService: PhysicianService,
                public settingsService: SettingsService) {
        if (get(data, 'model.id')) {
            this.subscription = this.physicianService.getPhysician(get(data, 'model.id')).subscribe(res => {
                this.physician = res;
            });
        }
    }

    ngOnInit(): void {

        this.physicianService.getStaffList().subscribe(data => {
            this.staffs = data;
        });

        this.settingsService.getSpecialities().subscribe(data => {
            this.specialities = data;
        });
    }

    handleSave() {
        this.subscription = this.physicianService.savePhysician(this.physician).subscribe(data => {
            this.dialogRef.close(data);
        });
    }

    compareCondition(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
    }
}
