import {Component, OnInit, ViewChild} from '@angular/core';
import {
    assign,
    assignIn,
    cloneDeep,
    filter,
    find,
    findIndex,
    get,
    head,
    isEmpty,
    isNil,
    map,
    noop,
    toUpper,
    concat,
    chain,
    reduce
} from 'lodash';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {PrescriptionService} from '../../services/prescription.service';
import * as moment from 'moment';
import {MatTabNav} from '@angular/material/tabs';
import {PatientService} from '../../../patient/services/patient.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {PhysicianService} from '../../../settings/services/physician.service';
import {SettingsService} from '../../../settings/services/settings.service';
import {MatDialog} from '@angular/material/dialog';
import {ExternalFilesDialogComponent} from '../../dialogs/external-files/external-files-dialog.component';
import {SendSmsDialogComponent} from '../../../shared/dialogs/send-sms/send-sms-dialog.component';
import {checkIsMobile} from '../../../shared/utils/functions';
import {AuthService, FtWsService, HEIGHT_ANIMATION, LARGE_DIALOG, MEDIUM_DIALOG, SCALE_ANIMATION} from '@ft/core';
import {SearchDialogComponent} from '../../../shared/dialogs/search-dialog/search-dialog.component';
import {PhysicianSetupComponent} from '../../../settings/dialogs/physician-setup/physician-setup.component';
import {CancellationDialogComponent} from "../../dialogs/cancellation/cancellation.component";
import {ClosureDialogComponent} from "../../dialogs/closure/closure.component";
import {RenewDialogComponent} from "../../dialogs/renew/renew.component";
import {SuspendDialogComponent} from "../../dialogs/suspend/suspend.component";
import {mergeMap} from "rxjs/operators";
import {FileManagerService, FtFile, VisualizerService} from "@ft/file-manager";
import {ForceClosureDialogComponent} from "../../dialogs/force-closure/force-closure.component";
import {ForceConformDialogComponent} from "../../dialogs/force-conform/force-conform.component";


@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    animations: [
        SCALE_ANIMATION,
        HEIGHT_ANIMATION
    ],

    styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
    @ViewChild('navBar', {static: true}) _navBar: MatTabNav;
    patient: any = {};
    patientPanel = false;
    prescriptions = [];
    navLinks: any[] = [];
    prescription = null;
    templateName = null;

    model: any = {date: moment(), start_date: moment().add(1, 'days')};
    prescriptionTemplate: any = null;
    subscription: Subscription;
    public files: File[] = [];
    physicians = [];
    isDoctor = false;
    editedItem = null;
    acceptedMimes = 'image/png,image/jpeg,image/jpg,application/pdf,application/gzip';
    isMobile = false;
    actions = [{
        icon: 'mdi-cloud-search',
        method: () => {
            return this.uploadExternalFiles();
        },
        tooltip: this.translateService.instant('prescription.external_files_import')
    }];
    physician = null;
    insurances = null;

    examFormTemplates = [];
    examForms = [];
    currentExamForm: any = {};
    uploadedFieldFiles = [];
    fileManagerContext: Observable<any>;
    isCloseAllowed = true;

    constructor(protected route: ActivatedRoute,
                protected router: Router,
                private prescriptionService: PrescriptionService,
                public patientService: PatientService,
                protected snackBar: MatSnackBar,
                public translateService: TranslateService,
                private physicianService: PhysicianService,
                private settingsService: SettingsService,
                private dialog: MatDialog,
                public core_auth: AuthService,
                private _managerService: FileManagerService,
                private _snackBar: MatSnackBar,
                private _translate: TranslateService,
                private _ws: FtWsService,
                private _previewDialog: VisualizerService
    ) {
    }

    ngOnInit(): void {
        this.isDoctor = this.settingsService.isDoctor();
        this.examFormTemplates = this.settingsService.prescriptionExamFormTemplates;
        this.route.queryParams.subscribe((queryParams: Params) => {
            this.templateName = get(queryParams, 'name');
            if (get(queryParams, 'pk')) {
                this.subscription = this.prescriptionService.getPrescription(get(queryParams, 'pk')).subscribe(data => {
                    this.prescriptionTemplate = get(data, 'content');
                    this.model = data;
                    this.prescription = data;
                    this.handlePhysicianChange();
                    this.loadExamForms();
                    this.fileManagerContext = new Observable(subscriber => {
                        subscriber.next({fileType: 'Report'});
                        subscriber.complete();
                    });

                });

            } else {
                this.prescriptionTemplate = cloneDeep(find(this.settingsService.prescriptionContentTemplates, (p) => {
                    return p.name === get(queryParams, 'name');
                }));
            }
        });

        this.route.parent.params.subscribe((params: Params) => {
            this.patientService.getPatient(get(params, 'id')).subscribe(data => {
                this.patient = data;
                this.handlePrescriptions();
            });
        });

        this.navLinks = map(this.settingsService.prescriptionContentTemplates, (p) => {
            return {
                name: p.name,
                label: p.label,
                isDisabled: p.disabled,
                path: '',
                params: {name: p.name}
            };
        });
        this.patientService.getInsurances().subscribe(cy => {
            this.insurances = cy;
        });
        this.onResize();

    }

    onResize() {
        this.isMobile = checkIsMobile();
    }

    handlePrescriptions() {
        this.subscription = this.prescriptionService.getPrescriptions(get(this.patient, 'id')).subscribe(data => {
            if (data) {
                this.prescriptions = data;
            }
            this.route.queryParams.subscribe(params => {
                if (get(params, 'pk')) {
                    this.prescription = find(this.prescriptions, (p) => {
                        return p.id === get(params, 'pk');
                    });
                } else {
                    if (!isEmpty(this.prescriptions) && !get(params, 'isNew')) {
                        this.prescription = head(this.prescriptions);
                        this.showPrescription(this.prescription);
                    }
                }
            });
        });
    }

    loadExamForms() {
        this.subscription = this.prescriptionService.getPrescriptionExamForms(this.model.id).subscribe(data => {
            this.examForms = data;
        });
    }

    showPrescription(p, editedItem = null) {
        this.prescription = p;
        this.editedItem = editedItem || this.editedItem;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: {
                name: p.content.name,
                pk: p.id
            }
        }).then(noop);
    }

    handleLink(link) {
        this.prescription = null;
        this.model = {date: moment(), start_date: moment().add(1, 'days')};

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    name: link.params.name,
                    pk: null,
                    isNew: true
                },
            }).then(noop);

    }

    removePrescription(p) {
        if (this.prescription && this.prescription.id === p.id) {
            this.prescription = null;
        }
        this.subscription = this.prescriptionService.deletePrescription(p.id).subscribe(data => {
            this.handlePrescriptions();
            this.router.navigate([], {
                queryParams: {
                    name: p.content.name,
                    pk: null,
                    isNew: true
                },
            }).then(noop);
        });
    }

    printPrescription() {
    }

    handleValue(f, l, ev) {
        let idx = findIndex(f.value, (v) => {
            return get(v, 'name') === get(l, 'name');
        });
        if (idx > -1) {
            f.value[idx].value = ev.checked;
        }
    }

    getValue(f, l) {
        let idx = findIndex(f.value, (v) => {
            return get(v, 'name') === get(l, 'name');
        });
        if (idx > -1) {
            return f.value[idx].value;
        }
    }

    handleSave(ev) {
        if (isNil(get(this.model, 'patient'))) {
            this.model.patient = this.patient;
        }

        if (this.isDoctor && !this.model.physician) {
            this.subscription = this.physicianService.getPhysicianByStaffId(this.core_auth.getStaff().id).subscribe(data => {
                if (data) {
                    this.model.physician = data;
                    this.save();
                }
            });
        } else {
            this.model.physician = !this.model.physician ? this.model.patient.physician : this.model.physician;
            this.save();
        }
    }

    save() {
        this.subscription = this.prescriptionService.savePrescription(assign(this.model, {
            content:
            this.prescriptionTemplate
        })).subscribe(data => {
            this.model = data;
            this.handlePrescriptions();
            this.snackBar.open(this.translateService.instant('shared.save_success'));

            this.router.navigate([], {
                relativeTo: this.route,
                queryParamsHandling: 'merge',
                queryParams: {
                    name: this.model.content.name,
                    pk: this.model.id
                }
            }).then(noop);
        });
    }

    exit() {
        this.router.navigate(['/patient/list']).then(noop);
    }

    getFullName() {
        return `${this.patient?.title ? this.patient?.title?.value : ''} ${toUpper(this.patient?.last_name)} ${toUpper(this.patient?.first_name)}`;
    }

    compareCondition(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
    }

    closePrescription(obj) {
        if (get(this.model, 'content.report_count') > 0) {
            this._managerService.readFolder('prescription.models.Prescription', this.model?.id, null, ['fileType'], null).subscribe(
                data => {
                    if (data.files.length < get(this.model, 'content.report_count')) {
                        this.snackBar.open(this.translateService.instant('prescription.close_not_allowed_warning'));
                        this.isCloseAllowed = false;
                    } else {
                        this.handleClose();
                    }
                }
            );
        } else {
            this.handleClose();
        }
        // return get(this.model, 'content.report_count') < 0;


    }

    handleClose() {
        this.dialog
            .open(
                ClosureDialogComponent,
                assignIn(MEDIUM_DIALOG, {
                    data: {},
                    disableClose: true,
                    autoFocus: false,
                })
            )
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.prescriptionService
                        .updatePrescription(this.model.id, {close_details: data, status: 'closed'})
                        .subscribe((res) => {
                            if (res) {
                                this.model = assignIn(this.model, data, {status: 'closed'});
                                this.handlePrescriptions();
                            }
                        });
                }
            });
    }

    uploadExternalFiles() {
        this.dialog.open(ExternalFilesDialogComponent, assignIn(MEDIUM_DIALOG,
            {
                data: {patient: get(this.patient, 'external_identifier')},
                disableClose: true,
                autoFocus: false
            }))
            .afterClosed().subscribe((data) => {
            if (!isEmpty(data)) {
                let items = filter(data, i => this.acceptedMimes.includes(i.mime));
                for (let item of items) {
                    this.prescriptionService.loadExternalFile({
                        pk: item.id,
                        file_name: item.name,
                        prescription_id: this.model.id
                    }).subscribe((res: any) => {
                            noop();
                        },
                        this.handleUploadError);
                }
            }
        });
    }

    handleUploadSuccess() {
        this.snackBar.open(this.translateService.instant('shared.upload_success'), null, {
            duration: 2000,
        });
    }

    handleUploadError() {
        this.snackBar.open(this.translateService.instant('shared.upload_file_error'), null, {
            duration: 2000,
        });
    }

    enableEdit(p) {
        this.editedItem = this.model.id;
        this.showPrescription(p, p.id);
    }

    sendSMS(p) {
        this.dialog.open(SendSmsDialogComponent, assignIn(MEDIUM_DIALOG,
            {
                data: {patient: get(this.model, 'patient'), physician: get(this.model, 'physician')},
                disableClose: true,
                autoFocus: false
            }))
            .afterClosed().subscribe(noop);
    }

    searchPhysician() {
        this.dialog.open(SearchDialogComponent, assignIn(LARGE_DIALOG,
            {
                data: null,
                disableClose: true,
                autoFocus: false
            }))
            .afterClosed().subscribe((data) => {
            if (data) {
                this.prescription.physician = data;
                this.handlePhysicianChange();
            }
        });
    }

    newPhysician() {
        this.dialog.open(PhysicianSetupComponent, assignIn(MEDIUM_DIALOG,
            {
                data: {model: null},
                disableClose: true,
                autoFocus: false
            }))
            .afterClosed().subscribe((data) => {
            if (data) {
                this.prescription.physician = data;
                this.handlePhysicianChange();
            }
        });
    }

    clearPhysician() {
        this.prescription.physician = null;
        this.physician = null;
    }

    handlePhysicianChange() {
        this.physician = `${get(this.prescription, 'physician.first_name', '')} ${get(this.prescription, 'physician.last_name', '')}`;
    }

    uploadFiles(files: File[]) {
    }

    handleExamForm(ef) {
        this.currentExamForm = {
            template: ef,
            prescription: {id: this.model.id},
        };
    }

    saveExamForm() {
        this.prescriptionService.savePrescriptionExamForm(assignIn(this.currentExamForm, {
            prescription: this.model.id
        })).subscribe(data => {
            if (data) {
                this.currentExamForm = data;
                this.loadExamForms();
            }
        });
    }

    deleteExamForm(pk) {
        this.prescriptionService.deletePrescriptionExamForms(pk).subscribe(data => {
            this.loadExamForms();
            this.currentExamForm = null;
        });
    }

    handleCancellation() {
        this.dialog
            .open(
                CancellationDialogComponent,
                assignIn(MEDIUM_DIALOG, {
                    data: {},
                    disableClose: true,
                    autoFocus: false,
                })
            )
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.prescriptionService
                        .updatePrescription(this.model.id, {cancellation_details: data})
                        .subscribe((res) => {
                            if (res) {
                                this.model = assignIn(this.model, {cancellation_details: data});
                            }
                        });
                }
            });
    }

    handleReactivate() {
        this.prescriptionService.updatePrescription(this.model.id, {
            close_details: null,
            status: 'entered'
        }).subscribe(data => {
            if (data) {
                this.model = assignIn(this.model, {status: 'entered'});
            }
        });
    }

    handleRenew() {
        this.dialog
            .open(
                RenewDialogComponent,
                assignIn(MEDIUM_DIALOG, {
                    data: {},
                    disableClose: true,
                    autoFocus: false,
                })
            )
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.prescriptionService
                        .updatePrescription(this.model.id, {renew_details: concat(this.model.renew_details || [], data)})
                        .subscribe((res) => {
                            if (res) {
                                this.model = assignIn(this.model, {renew_details: concat(this.model.renew_details || [], data)});
                            }
                        });
                }
            });
    }

    handleSuspend() {
        this.dialog
            .open(
                SuspendDialogComponent,
                assignIn(MEDIUM_DIALOG, {
                    data: {},
                    disableClose: true,
                    autoFocus: false,
                })
            )
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.prescriptionService
                        .updatePrescription(this.model.id, {suspend_details: data})
                        .subscribe((res) => {
                            if (res) {
                                this.model = assignIn(this.model, {suspend_details: data});
                            }
                        });
                }
            });
    }

    handleForceConform() {
        this.dialog
            .open(
                ForceConformDialogComponent,
                assignIn(MEDIUM_DIALOG, {
                    data: {},
                    disableClose: true,
                    autoFocus: false,
                })
            )
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.prescriptionService
                        .updatePrescription(this.model.id, {force_conform_details: data})
                        .subscribe((res) => {
                            if (res) {
                                this.model = assignIn(this.model, {force_conform_details: data});
                            }
                        });
                }
            });
    }

    getLastRenewDetails() {
        return get(this.model, 'renew_details', []).slice(-1);
    }


    uploadFieldFiles(event) {

        const filteredFiles: File[] = get(event, 'filteredFiles');
        const field = get(event, 'field');
        const block_name = get(event, 'block_name');
        const blockIdx = get(event, 'blockIdx');
        const fieldIdx = get(event, 'fieldIdx');
        const context$ = {field_name: field.name, block_name};

        this.subscription = this._managerService.readFolder(
            'prescription.models.Prescription', this.model?.id, null, [], context$).pipe(
            mergeMap((currentFolder) => this._managerService.uploadFiles(filteredFiles, currentFolder?.id, context$))
        )
            .subscribe(
                data => {
                    this._managerService.readFolder('prescription.models.Prescription', this.model?.id, data.id, ['field_name', 'block_name'], context$).subscribe(d => {

                        this.model.content.blocks[blockIdx].fields[fieldIdx].value = {
                            file_name: get(filteredFiles, '0.name'),
                            file_id: (chain(d.files).find({context: context$}) as any).get('id').value()
                        };
                        this.prescriptionService.updatePrescription(this.model?.id, {content: this.model?.content}).subscribe(noop);
                        this.subscription.unsubscribe();

                    });
                },
                (e) => {
                    this._snackBar.open(this._translate.instant('file_manager.upload_error'));
                }
            );
    }

    handleFieldFileDelete(ev) {
        if (ev) {
            this.prescriptionService.updatePrescription(this.model?.id, {content: this.model?.content}).subscribe(noop);
        }
    }

    isContentConform() {
        if (get(this.model, 'id')) {
            return chain(this.model?.content?.blocks).map('fields').flatten().filter({type: 'file'}).find(e => {
                return e.required && isEmpty(e.value);
            }).isEmpty().value();
        }
    }


    handleForceClose() {
        this.dialog
            .open(
                ForceClosureDialogComponent,
                assignIn(MEDIUM_DIALOG, {
                    data: {},
                    disableClose: true,
                    autoFocus: false,
                })
            )
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    this.prescriptionService
                        .updatePrescription(this.model.id, {force_close_details: data})
                        .subscribe((res) => {
                            if (res) {
                                this.model = assignIn(this.model, {force_close_details: data});
                            }
                        });
                }
            });
    }

    previewFieldFile(event) {
        const field = get(event, 'field');
        const fileId = get(event, 'fileId');
        const blockName = get(event, 'blockName');
        const context$ = {prescription: this.model?.id, field_name: field, blockName};
        this._managerService.readFolder('prescription.models.Prescription', this.model?.id, null, ['field_name', 'block_name'], null).subscribe(
            data => {
                const res: [number, FtFile[]] = data.visualizedFiles(fileId);
                const file = find(data.files, {id: fileId});

                if (file.type) {
                    file.is_converted ?
                        this._previewDialog.open({
                            data: res[1],
                            index: res[0]
                        }) :
                        this._snackBar.open(this._translate.instant('file_manager.converted_msg'));
                } else {
                    this._managerService.download(file.id, file.name)
                        .subscribe(download => download());
                }
            }
        );

    }

}
