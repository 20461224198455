import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PatientService} from '../../services/patient.service';
import {assignIn, get, isNil, noop, last, has, pick} from 'lodash';
import {Subscription} from 'rxjs';
import {PhysicianService} from '../../../settings/services/physician.service';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {PhysicianSetupComponent} from '../../../settings/dialogs/physician-setup/physician-setup.component';
import {SearchDialogComponent} from '../../../shared/dialogs/search-dialog/search-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, DefaultValuesService, LARGE_DIALOG, MEDIUM_DIALOG, SelectConfig, SMALL_DIALOG} from '@ft/core';
import {SettingsService} from '../../../settings/services/settings.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {PatientDeathDialogComponent} from '../../dialogs/patient-death/patient-death.component';
import {PatientAccountSetupComponent} from '../../dialogs/patient-account-setup/patient-account-setup.component';
import {PatientResumeComponent} from '../../dialogs/patient-resume/patient-resume.component';

@Component({
  selector: 'app-patient-base',
  template: '',
  styleUrls: [],
})
export class PatientBaseComponent implements OnInit {
  patient: any = {
    city: this.defaultValuesService.getValue('city'),
    country: this.defaultValuesService.getValue('country')
  };
  titles: any = [];
  countries: any = [];
  cities: any = [];
  insurances: any = [];
  subscription: Subscription;
  isDoctor = false;
  physician = null;
  isMobile = false;
  afterSave = this.handleAfterSave.bind(this);
  public breakpoints = Breakpoints;
  maritalStatus = [];
  professions = [];
  provinces = [];
  uploadedAvatar: Blob[] = [];
  public physicianSelectConfig: SelectConfig = {key: 'full_name', url: 'api/pl/physician/'};
  public physicianDialogClass = PhysicianSetupComponent;
  public preventSave = false;

  constructor(
    public patientService: PatientService,
    public physicianService: PhysicianService,
    public _adapter: MomentDateAdapter,
    public dialog: MatDialog,
    public core_auth: AuthService,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    public router: Router,
    public route: ActivatedRoute,
    public settingService: SettingsService,
    public breakpointObserver: BreakpointObserver,
    public defaultValuesService: DefaultValuesService) {
  }

  ngOnInit(): void {
    this.isDoctor = this.settingService.isDoctor();
    this.patientService.getTitles().subscribe(t => {
      this.titles = t;
    });
    this.patientService.getCountries().subscribe(c => {
      this.countries = c;
    });
    this.patientService.getCities().subscribe(cy => {
      this.cities = cy;
    });
    this.patientService.getInsurances().subscribe(cy => {
      this.insurances = cy;
    });
    this.patientService.getMaritalStatus().subscribe(cy => {
      this.maritalStatus = cy;
    });
    this.patientService.getProfessions().subscribe(cy => {
      this.professions = cy;
    });
    this.patientService.getProvince().subscribe(cy => {
      this.provinces = cy;
    });
    // this.onResize();

    this.route.params.subscribe(params => {
      if (get(params, 'id')) {
        this.subscription = this.patientService.getPatient(get(params, 'id')).subscribe(data => {
          this.patient = data;
        });
      }
    });


  }


  handleSave() {
    if (this.isDoctor && !this.patient.physician) {
      this.subscription = this.physicianService.getPhysicianByStaffId(this.core_auth.getStaff().id).subscribe(data => {
        if (data) {
          this.patient.physician = data;
          this.performSave();
        } else {
          this.handleSaveError();
        }
      });
    } else {
      this.performSave();
    }
  }

  handleCancel() {
    this.router.navigate(['/patient/list']);
  }

  performSave() {
    if (has(this.patient, 'id')) {
      this.handlePost();
    } else {
      this.patientService.checkExistence(pick(this.patient, ['first_name', 'last_name'])).subscribe(this.handlePrePost.bind(this), this.failCall.bind(this));
    }
  }

  handlePrePost(data) {
    if (get(data, 'exist')) {
      this.dialog.open(PatientResumeComponent, assignIn(SMALL_DIALOG,
        {
          data: get(data, 'data'),
          disableClose: true,
          autoFocus: false
        }))
        .afterClosed().subscribe((res) => {
        let force = get(res, 'force', null);
        if (!isNil(force)) {
          if (force) {
            this.handlePost();

          } else {
            this.router.navigate([`patient/form/${get(data, 'data.id')}`]).then(noop);
          }
        }
      });
    } else {
      this.handlePost();

    }
  }

  handlePost() {
    if (!this.preventSave) {
      this.preventSave = true;
      this.subscription = this.patientService.savePatient(this.patient).subscribe(this.afterSave.bind(this), this.failCall.bind(this));
    }
  }

  failCall(err) {
    this.preventSave = false;
  }

  compareCondition(o1, o2) {
    return o1 && o2 && o1.id === o2.id;
  }

  handleTitleChange(ev) {
    this.patient.gender = get(this.patient, 'title.gender');
  }

  handleBirthDateChange(date) {
    let age = isNil(date) ? null : this._adapter.today().diff(date, 'years');
    if (age === 0) {
      age = this._adapter.today().diff(date, 'months');
    }
    this.patient.age = age;
  }

  searchPhysician() {
    this.dialog.open(SearchDialogComponent, assignIn(LARGE_DIALOG,
      {
        data: null,
        disableClose: true,
        autoFocus: false
      }))
      .afterClosed().subscribe((data) => {
      if (data) {
        this.patient.physician = data;
      }
    });
  }

  newPhysician() {
    this.dialog.open(PhysicianSetupComponent, assignIn(MEDIUM_DIALOG,
      {
        data: {model: null},
        disableClose: true,
        autoFocus: false
      }))
      .afterClosed().subscribe((data) => {
      if (data) {
        this.patient.physician = data;
      }
    });
  }

  clearPhysician() {
    this.patient.physician = null;
    this.physician = null;
  }


  // onResize() {
  //     this.isMobile = checkIsMobile();
  // }

  handleAfterSave(data) {
    this.snackBar.open(this.translateService.instant('shared.save_success'), null, {
      horizontalPosition: 'left',
      duration: 200
    });

    this.router.navigate([`patient/patient-form/${data['id']}`]).then(noop);
    this.preventSave = false;
  }

  handleSaveError() {
    this.snackBar.open(this.translateService.instant('shared.save_error'), null, {
      horizontalPosition: 'left',
      duration: 1000
    });
  }

  exit() {
    this.router.navigate(['/patient/list']).then(noop);
  }

  uploadAvatar(files: File[]) {
    this.convertToBase64(last(files)).then(data => {
      this.patient.picture = data;
      this.uploadedAvatar = [];
    });
  }

  public convertToBase64(file: any) {
    if (file instanceof Blob) {
      return new Promise((resolve, reject) => {
        const r = new FileReader();
        r.readAsDataURL(file);
        r.onload = () => resolve(r.result);
        r.onerror = error => reject(error);
      });
    }
  }

  handleDeathDetail() {
    this.dialog
      .open(
        PatientDeathDialogComponent,
        assignIn(SMALL_DIALOG, {
          data: {
            death_date: get(this.patient, 'death_date'),
            death_reason: get(this.patient, 'death_reason'),
          },
          disableClose: true,
          autoFocus: false,
        })
      )
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.patientService
            .updatePatient(this.patient.id, data)
            .subscribe((res) => {
              if (res) {
                this.patient = assignIn(this.patient, data);
              }
            });
        }
      });
  }

  removePatient() {
    this.subscription = this.patientService
      .allowPatientDelete(this.patient?.id)
      .subscribe((data) => {
        if (data) {
          this.subscription = this.patientService
            .deletePatient(this.patient?.id)
            .subscribe(this.handleCancel.bind(this));
        } else {
          this.snackBar.open(
            this.translateService.instant('shared.delete_error')
          );
        }
      });
  }

  handleUserAccount() {
    this.dialog.open(PatientAccountSetupComponent, assignIn(SMALL_DIALOG,
      {
        data: {model: null},
        disableClose: true,
        autoFocus: false
      }))
      .afterClosed().subscribe((data) => {
      if (data) {
        this.patient.staff = data;
      }
    });
  }
}
