import { ViewValueType } from '../types/shared.type';
export const RecipientList: ViewValueType[] = [
  {
    value: 'prescription.patient.default_staff',
    viewValue: 'settings.acl.patient',
    resourceName: 'ticket',
  }, // TODO view value must be a translate instant
  {
    value: 'prescription.physician.staff',
    viewValue: 'settings.acl.physician',
    resourceName: 'ticket',
  },
  {
    value: 'assignee',
    viewValue: 'settings.acl.technician',
    resourceName: 'ticket',
  },
];
