import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {filter, find, get, has, head} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService, FtWsService} from '@ft/core';
import {FormSetupService} from "@ft/form";

@Injectable()
export class SettingsService {
    private _formTemplates$: BehaviorSubject<any> = new BehaviorSubject([]);
    private staffDetail: any;

    constructor(private httpClient: HttpClient, private ws: FtWsService, private _authService: AuthService, private formService: FormSetupService) {

    }

    set formTemplates(value: any) {
        this._formTemplates$.next(value);
    }

    get formTemplates(): any {
        return this._formTemplates$.getValue();
    }

    get prescriptionContentTemplates(): any {
        return filter(this.formService.formsTemplates, {'category': 'PRESCRIPTION_TEMPLATE'});
    }

    get prescriptionExamFormTemplates(): any {
        return filter(this.formService.formsTemplates, {'category': 'PRESCRIPTION_EXAM_FORM'});
    }

    get prescriptionSatisfactionFormTemplate(): any {
        return find(this.formService.formsTemplates, {'category': 'PRESCRIPTION_SATISFACTION_FORM', 'is_default': true});
    }

    loadStaff(): Observable<boolean> {
        return this.ws.call(`physician.load_staff_detail`, {pk: get(this._authService.getStaff(), 'id')})
            .pipe(
                map(data => this.staffDetail = data),
            );
    }

    savePrescriptionTemplate(model) {
        return this.httpClient.post(`api/pl/prescription-template/${has(model, 'id') ? get(model, 'id') + '/' : ''}`, model);
    }

    deletePrescriptionTemplate(pk) {
        return this.httpClient.delete(`api/pl/prescription-template/${pk}/`);
    }


    allowTemplateEdition(name) {
        return this.ws.call(`prescription.allow_template_edition`, {name});
    }


    getSmsHistory() {
        return this.httpClient.get<any>(`api/pl/sms-history/`);
    }


    getSpecialities() {
        return this.httpClient.get<any>(`api/staff/speciality/`);
    }

    getSpeciality(pk) {
        return this.httpClient.get<any>(`api/staff/speciality/${pk}/`);
    }

    saveSpeciality(pk, data) {
        if (!pk) {
            return this.httpClient.post<any>(`api/staff/speciality/`, data);
        } else {
            return this.httpClient.put<any>(`api/staff/speciality/${pk}/`, data);
        }
    }

    deleteSpeciality(pk) {
        return this.httpClient.delete<any>(`api/staff/speciality/${pk}/`);
    }

    deleteSmsTemplate(pk) {
        return this.httpClient.delete<any>(`/api/pl/sms-template/${pk}/`);
    }

    getSmsTemplates() {
        return this.httpClient.get<any>(`/api/pl/sms-template/`);
    }

    saveSmsTemplate(model) {
        if (get(model, 'id')) {
            return this.httpClient.put(`/api/pl/sms-template/${get(model, 'id')}/`, model);
        } else {
            return this.httpClient.post(`/api/pl/sms-template/`, model);
        }
    }

    public isDoctor(): boolean {
        return get(this.staffDetail, 'is_doctor', false);
    }

    getDefaultPrescriptionTemplate() {
        const p = find(this.prescriptionContentTemplates, (e) => {
            return e.is_default;
        }) || head(this.prescriptionContentTemplates);
        return get(p, 'name');
    }
}
