<div
  [ngClass]="{
    'toolbar-container-md': isMobile
  }"
>
  <mat-toolbar
    color="primary"
    [ngClass]="{
      'toolbar-md': isMobile
    }"
  >
    <div class="logo" fxLayoutAlign="center center">
      <div
        [ngClass]="{
          'logo-style-for-mobile': !breakpointObserver.isMatched(
            '(min-width: 1280px)'
          )
        }"
      >
        <img [src]="logo" alt=""/>
      </div>
    </div>
    <!-- <nav mat-tab-nav-bar class="toolbar-links" fxFlex="nogrow" fxLayout="row">
            <ng-container *ngFor="let link of links">
                <ng-template [ftAcl]="link.acl">
                    <a mat-tab-link
                       [routerLink]="link.path" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
                       [ngClass]="{'with-icon': (link.icon && link.label), 'icon-only': (link.icon && !link.label || link.icon && isMobile)}"
                       [target]="link.target">
                        <ng-container *ngTemplateOutlet="linkContent;context:{link: link}"></ng-container>
                    </a>
                </ng-template>
            </ng-container>
        </nav> -->
    <div fxFlex></div>
    <div
      *ngIf="user?.username"
      class="toolbar-tools"
      fxFlex="nogrow"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <button
        *ngIf="notify"
        mat-icon-button
        class="account notification"
        matTooltip="notifications"
        [matMenuTriggerFor]="notificationMenu"
        (menuOpened)="menuOpened()" (menuClosed)="menuOpened(false)"
      >
        <mat-icon fontIcon="mdi-bell" fontSet="mdi"></mat-icon>
        <span *ngIf="unread_notification" class="badge">{{ unread_notification}}</span>
      </button>
      <mat-menu #notificationMenu="matMenu" xPosition="before">
        <button
          mat-menu-item
          class="notification-item"
          *ngFor="let appointment of displayedAppointments"
          [ngClass]="{'notification-item-read': appointment.read}"
          (click)="readNotification(appointment)"
        >
          <span class="ntf-reason">{{ appointment.text }}</span>
          <span class="ntf-creation-date">
            <span
              [matTooltip]="getLocalDate(appointment.created_at_string)">{{ fromNow(appointment.created_at) }}</span>
          </span>
        </button>

        <div class="flex justify-center items-center py-2"
             *ngIf="this.unread_notification && this.unread_notification>0">
          <button mat-icon-button (click)="_fetchAppointmentNotifications(-1);$event.stopPropagation()"
                  [disabled]="!has_prev_notification_page">
            <mat-icon fontIcon="mdi-chevron-left" fontSet="mdi"></mat-icon>

          </button>
          <span class="text-sm">
                  {{handlePageInfos()}}
          </span>
          <button mat-icon-button (click)="_fetchAppointmentNotifications(1);$event.stopPropagation()"
                  [disabled]="!has_next_notification_page">
            <mat-icon fontIcon="mdi-chevron-right" fontSet="mdi"></mat-icon>

          </button>

        </div>
        <div class="flex justify-center items-center p-2"
             *ngIf="!this.unread_notification || this.unread_notification==0">
          {{"shared.no_element_to_show"| translate}}
        </div>
      </mat-menu>
      <ng-template [ftAcl]="{ resource: 'config', action: 'update' }">
        <button
          *ngIf="breakpointObserver.isMatched('(min-width: 1280px)')"
          mat-icon-button
          routerLink="settings"
          [matTooltip]="'shared.settings' | translate"
        >
          <mat-icon fontIcon="mdi-cog" fontSet="mdi"></mat-icon>
        </button>
      </ng-template>
      <button
        *ngIf="breakpointObserver.isMatched('(min-width: 1280px)')"
        mat-icon-button
        class="account"
        [matTooltip]="user.staff + user.username"
      >
        <mat-icon fontIcon="mdi-account" fontSet="mdi"></mat-icon>
      </button>
      <button
        *ngIf="breakpointObserver.isMatched('(min-width: 1280px)')"
        color="warn"
        class="logout"
        mat-icon-button
        (click)="logout()"
        [matTooltip]="'shared.disconnect' | translate"
      >
        <mat-icon fontIcon="mdi-logout" fontSet="mdi"></mat-icon>
      </button>
      <img
        *ngIf="!breakpointObserver.isMatched('(min-width: 1280px)')"
        class="toolbar-img"
        [src]="user?.avatar ? user?.avatar : '/assets/icons/default-avatar.svg'"
        alt=""
        [matMenuTriggerFor]="disconnectMenu"
      />
      <mat-menu #disconnectMenu="matMenu" class="disconnect-menu">
        <div fxLayoutAlign="center center">
          <span>{{ user.username }}</span> &nbsp;:&nbsp;
          <span>{{ user.staff }}</span>
        </div>
        <button mat-menu-item (click)="logout()">
          <mat-icon fontSet="mdi" fontIcon="mdi-logout"></mat-icon>
          <span>{{ "shared.disconnect" | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <ng-template let-link="link" #linkContent>
    <ng-container *ngIf="link.icon">
      <mat-icon [fontIcon]="link.icon" fontSet="mdi"></mat-icon>
    </ng-container>
    <ng-container *ngIf="link.label && !isMobile">
      <span [translate]="link.label"></span>
    </ng-container>
  </ng-template>
</div>
