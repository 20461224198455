<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-contacts"></mat-icon>
    </button>
  </div>
  <h2>{{ 'settings.physician' | translate }}</h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm">
  <div [ngBusy]="subscription"></div>

  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div fxLayout="column" fxLayoutGap="6px" class="grid grid-flow-row-dense grid-cols-12 gap-x-4 gap-y-1">


      <mat-form-field fxFlex class="md:col-span-6 col-span-12">
        <mat-label>{{'patient.last_name'|translate}}</mat-label>
        <input matInput type="text" required name="last_name"
               [ftAutoFocus]="true"
               [(ngModel)]="physician.last_name"/>
      </mat-form-field>
      <mat-form-field fxFlex class="md:col-span-6 col-span-12">
        <mat-label>{{'patient.first_name'|translate}}</mat-label>
        <input matInput type="text" required name="first_name"
               [(ngModel)]="physician.first_name"/>
      </mat-form-field>


      <mat-form-field fxFlex class="md:col-span-6 col-span-12">
        <mat-label>{{'patient.phone_number'|translate}}</mat-label>
        <input matInput type="tel" name="phone_number"
               [(ngModel)]="physician.phone_number" pattern="^[0-9]{5,12}$"/>
      </mat-form-field>
      <mat-form-field fxFlex class="md:col-span-6 col-span-12">
        <mat-label>{{'patient.email'|translate}}</mat-label>
        <input matInput type="email" name="email"
               [(ngModel)]="physician.email" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"/>
      </mat-form-field>


      <mat-form-field fxFlex class="md:col-span-6 col-span-12">
        <mat-label>{{'settings.inpe'|translate}}</mat-label>
        <input matInput type="text" name="inpe"
               [(ngModel)]="physician.inpe" pattern="^[0-9]{5,12}$"/>
      </mat-form-field>
      <mat-form-field fxFlex class="md:col-span-6 col-span-12">
        <mat-label>{{'settings.ice'|translate}}</mat-label>
        <input matInput type="text" name="ice"
               [(ngModel)]="physician.ice"  />
      </mat-form-field>

      <mat-form-field fxFlex class="md:col-span-6 col-span-12">
        <mat-label>{{'settings.speciality' | translate}}</mat-label>
        <mat-select [(ngModel)]="physician.speciality" name="speciality" [compareWith]="compareCondition">
          <mat-option *ngFor="let t of specialities" [value]="t">
            {{t.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-template [ftAcl]="{resource: 'user', action: 'create'}">
        <mat-form-field fxFlex class="md:col-span-6 col-span-12">
          <mat-label>{{'settings.user' | translate}}</mat-label>
          <mat-select [(ngModel)]="physician.staff" name="user" [compareWith]="compareCondition">
            <mat-option *ngFor="let s of staffs" [value]="s">
              {{s.first_name}} {{s.last_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>

    </div>
  </div>

  <div mat-dialog-actions>
    <span fxFlex></span>
    <button type="button" mat-flat-button color="primary" (click)="handleSave()" [disabled]="form.invalid">
      <span>{{'shared.save'|translate}}</span>
    </button>
    <button type="button" mat-flat-button color="warn" [mat-dialog-close]="null" cdkFocusInitial>
      <span>{{'shared.reset'|translate}}</span>
    </button>
  </div>
</form>
